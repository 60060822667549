import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Sidebar from "../../../components/Sidebar";
import Wrapper from "../../../components/Wrapper";
import Inner from "../../../components/Inner";
import { Container, ContainerFile } from "./styled";
import { FiArrowLeft, FiArrowUpRight, FiFile, FiGlobe, FiImage, FiUser } from "react-icons/fi";
import TitlePageSection from "../../../components/TitlePageSection";
import dayjs from "dayjs";
import { LOGOS_COMPANIAS } from "../../../constants/logos-companias";
import { useForm } from "../../../hooks/useForm";
import { editarSiniestrosReglas } from "../../../utils/formRules";
import NewButton from "../../../components/NewButton";
import Estado from "../../../components/Estado";
import { getAdjuntosSiniestros } from "../../../services/Siniestros";
import { useTabs } from "../../../hooks/useTabs";
import NewTabs from "../../../components/NewTabs";
import useSiniestros from "../../../hooks/useSiniestros";
import { useLoading } from "../../../hooks/useLoading";
import SiniestroInformacionTab from "../../../components/Tabs/Siniestro/Informacion";
import SiniestroDocumentacionTab from "../../../components/Tabs/Siniestro/Documentacion";
import BackButton from "../../../components/BackButton";
import { SINIESTRO_TABS } from "../../../constants/tabs";
import Divider from "../../../components/Divider";
import Subtitle from "../../../components/Subtitle";
import { Col, Grid } from "../../../components/Grid";
import {
  CLIENTE_LABELS,
  PAGO_LABELS,
  SERVICIO_LABELS,
} from "../../../constants/labels";
import {
  desencriptarPago,
  getAutogestionArchivos,
  obtenerContrataciones,
} from "../../../services/Autogestion";
import downloadFile from "../../../utils/downloadFile";
import SpinnerLoading from "../../../components/SpinnerLoading";
import ImgGys from "../../../components/ImgGys/Index";
import ImgGys2 from "../../../components/ImgGys2";
import CardSinResultados from "../../../components/CardSinResultados";
import docVideo from "../../../assets/images/docVideo.png";

const ContratacionAutogestion = () => {
  const operacionId = useParams();
  const location = useLocation();
  const { loading, setLoading } = useLoading(true);
  const { handleTab, tabSelected } = useTabs();

  const [contratacion, setContratacion] = useState(location?.state?.contratacion
    ? location.state.contratacion
    : [])
  const [pago, setPago] = useState({});

  console.log(operacionId)
  console.log(contratacion)

  useEffect(() => {
    console.log(contratacion)
    const getContratacion = async () => {
      console.log(contratacion)
      if(contratacion.length === 0) {
        const contratacionesLength = await obtenerContrataciones(null, null, null)
        console.log(contratacionesLength)
        const findContratacion = contratacionesLength.find(item => item.id_operacion == operacionId.id)
        console.log(findContratacion)
        let desencriptados = await desencriptarPago(findContratacion.pago);
        const archivos = await getAutogestionArchivos(
          findContratacion.id_operacion
        );
        const archivosDescargados =
          archivos.length > 0
            ? await Promise.all(
                archivos.map(async (archivo) => {
                  const file = await downloadFile(archivo.url);
                  const type = archivo.url.split("/")[6];
                  return { ...archivo, url: file, type: type };
                })
              )
            : [];
        const data = { ...findContratacion };

        data.pago = {
          cbu: desencriptados.cbu_decrypted,
          marca_tarjeta: desencriptados.marca_tarjeta_decrypted,
          nro_tarjeta: desencriptados.nro_tarjeta_decrypted,
          nombre_titular: desencriptados.nombre_titular_decrypted,
          dni_titular: desencriptados.dni_titular_decrypted,
          vto_tarjeta: desencriptados.vto_tarjeta_decrypted,
          banco: desencriptados.banco_decrypted,
        };
        data.archivos = archivosDescargados;

        console.log(data)
        setContratacion(data)
        setPago(data);
        setLoading(false);
      }
    } 
    getContratacion()
  }, [])

  useEffect(() => {
    const getPago = async () => {
      setLoading(true);
      if (location?.state?.contratacion) {
        setPago(null);
        try {
          let desencriptados = await desencriptarPago(contratacion.pago);
          const archivos = await getAutogestionArchivos(
            contratacion.id_operacion
          );
          const archivosDescargados =
            archivos.length > 0
              ? await Promise.all(
                  archivos.map(async (archivo) => {
                    const file = await downloadFile(archivo.url);
                    const type = archivo.url.split("/")[6];
                    return { ...archivo, url: file, type: type };
                  })
                )
              : [];
          const data = { ...contratacion };

          data.pago = {
            cbu: desencriptados.cbu_decrypted,
            marca_tarjeta: desencriptados.marca_tarjeta_decrypted,
            nro_tarjeta: desencriptados.nro_tarjeta_decrypted,
            nombre_titular: desencriptados.nombre_titular_decrypted,
            dni_titular: desencriptados.dni_titular_decrypted,
            vto_tarjeta: desencriptados.vto_tarjeta_decrypted,
            banco: desencriptados.banco_decrypted,
          };
          data.archivos = archivosDescargados;
          setPago(data);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    };
    getPago();
  }, [location?.state?.contratacion]);

  const logo = LOGOS_COMPANIAS.find(
    (item) =>
      item.nombre.toLowerCase() === contratacion?.servicio?.compania.toLowerCase()
  )?.url;

  return (
    <div>
      <div>
        <Sidebar />
        <Wrapper>
          <Inner>
            <Container>
              {!loading ? (
                <>
                  <div>
                    <BackButton
                      url={"/Autogestion"}
                      text="Autogestión - Emisión"
                    />
                    <TitlePageSection
                      title={`Autogestión ${contratacion?.cliente?.nombre}`}
                      description={`Contratación desde landing realizada el dia ${contratacion?.fecha}. Número de operación ${contratacion?.id_operacion}.`}
                    ></TitlePageSection>
                  </div>
                  <div className="header-section">
                    <img
                      src={logo}
                      className="logo-compania"
                      alt="logo de la compañia"
                    />
                    <span className="header-info autogestion">
                      <span>
                        <FiGlobe />
                      </span>{" "}
                      Autogestión
                    </span>
                  </div>
                  <NewTabs
                    tabs={SINIESTRO_TABS}
                    tabSelected={tabSelected}
                    handleTab={handleTab}
                  />

                  {tabSelected === 0 ? (
                    <>
                      <Subtitle
                        icon={<FiUser />}
                        title={"Cliente"}
                        description={
                          "Datos brindados durante la contratación online"
                        }
                        disableDivider={true}
                      />
                      <Grid colGap={21} rowGap={21} narrow={true}>
                        {Object.entries(contratacion.cliente).map(
                          (item, index) => {
                            if (item[1]) {
                              const esValorMonetario =
                                typeof item[1] === "number" &&
                                (CLIENTE_LABELS[item[0]].includes("Suma") ||
                                  CLIENTE_LABELS[item[0]].includes("Valor"));
                              return (
                                <Col desktop={3} key={index}>
                                  <div className={`premio-container`}>
                                    <span>{CLIENTE_LABELS[item[0]]}</span>
                                    <h2>
                                      {esValorMonetario ? "$" : null}
                                      {item[1]}
                                    </h2>
                                  </div>
                                </Col>
                              );
                            }
                          }
                        )}
                      </Grid>

                      <Divider />

                      <Subtitle
                        icon={<FiUser />}
                        title={"Servicio"}
                        description={
                          "Datos brindados sobre el servicio contratado"
                        }
                        disableDivider={true}
                      />
                      <Grid colGap={21} rowGap={21} narrow={true}>
                        {Object.entries(contratacion.servicio).map(
                          (item, index) => {
                            if (item[1]) {
                              const esValorMonetario =
                                typeof item[1] === "number" &&
                                (SERVICIO_LABELS[item[0]].includes("Suma") ||
                                  SERVICIO_LABELS[item[0]].includes("Valor"));
                              const esLink = typeof item[1] === "string" ? item[1].includes("https") : null
                              return (
                                <Col desktop={3} key={index}>
                                  <div className={`premio-container`}>
                                    <span>{SERVICIO_LABELS[item[0]]}</span>
                                    {!esLink ?
                                    <h2>
                                      {esValorMonetario ? "$" : null}
                                      {item[1]}
                                    </h2>
                                    : 
                                    <a href={item[1]} target="_blink"> 
                                        <h2 className="link">
                                            Ver link <FiArrowUpRight />
                                        </h2>
                                    </a>
                                    }
                                  </div>
                                </Col>
                              );
                            }
                          }
                        )}
                      </Grid>

                      <Divider />

                      {pago?.pago ? (
                        <>
                          <Subtitle
                            icon={<FiUser />}
                            title={"Forma de pago"}
                            description={
                              "Datos brindados sobre el método de pago"
                            }
                            disableDivider={true}
                          />
                          <Grid colGap={21} rowGap={21} narrow={true}>
                            {Object.entries(pago.pago).map((item, index) => {
                              if (item[1]) {
                                const esValorMonetario =
                                  typeof item[1] === "number" &&
                                  (PAGO_LABELS[item[0]].includes("Suma") ||
                                    PAGO_LABELS[item[0]].includes("Valor"));
                                return (
                                  <Col desktop={3} key={index}>
                                    <div className={`premio-container`}>
                                      <span>{PAGO_LABELS[item[0]]}</span>
                                      <h2>
                                        {esValorMonetario ? "$" : null}
                                        {item[1]}
                                      </h2>
                                    </div>
                                  </Col>
                                );
                              }
                            })}
                          </Grid>
                        </>
                      ) : null}
                    </>
                  ) : null}

                  {tabSelected === 1 ? (
                    <>
                      <Subtitle
                        icon={<FiImage />}
                        title={"Documentación"}
                        description={
                          "Archivos cargados por el cliente durante la contratación"
                        }
                        disableDivider={true}
                      />
                      {pago.archivos.length > 0 ? (
                        <Grid colGap={21} rowGap={21} narrow={true}>
                          {pago.archivos.map((archivo, index) => {
                            let file = null;
                            if (archivo.type === "image") {
                              file = (
                                <ImgGys
                                  url={archivo.url}
                                  index={index}
                                  descarga={false}
                                />
                              );
                            } else if (archivo.type === "video") {
                              file = (
                                <img
                                  src={docVideo}
                                  alt={"documento"}
                                  descarga={false}
                                />
                              );
                            } else {
                              file = (
                                <ImgGys2
                                  url={archivo.url}
                                  index={index}
                                  descarga={false}
                                />
                              );
                            }
                            return (
                              <Col desktop={2}>
                                <ContainerFile>
                                  <label>
                                    {archivo.nombre_campo
                                      .replace("archivo_", " ")
                                      .replaceAll("_", " ")}
                                  </label>
                                  {file}
                                </ContainerFile>
                              </Col>
                            );
                          })}
                        </Grid>
                      ) : (
                        <CardSinResultados
                          icon={<FiFile />}
                          title={"No hay documentación"}
                          description={
                            "No se encontraron archivos cargados para la contratación"
                          }
                        />
                      )}
                    </>
                  ) : null}
                </>
              ) : (
                <SpinnerLoading />
              )}
            </Container>
          </Inner>
        </Wrapper>
      </div>
    </div>
  );
};

export default ContratacionAutogestion;
