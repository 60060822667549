import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function useFiltersNew(filterValues, callbackRequired){
    const [params, setParams] = useState("")
    const [inputValues, setInputValues] = useState(filterValues)
    const location = useLocation();
    const history = useHistory()
    const queryParams = new URLSearchParams(location.search);

    // Completar campos de los filtros
    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputValues((prev) => ({ ...prev, [name]: value }));
    };

    // Completar campos de los filtros
    const handleChangeExternal = (e, name, value) => {
        //setInputValues((prev) => ({ ...prev, [name]: value }))
        const data = { ...inputValues, [name]: value }
        setInputValues((prev) => ({ ...prev, [name]: value }));
        handleSearch(e, data)
    }
    
    // Buscar con filtros
    const handleSearch = async (e, data) => {
        const filters = data ? data : inputValues
        console.log(filters)
        if(callbackRequired){
            callbackRequired(inputValues)
        } else {
            let valuesCompleted = [];
            for (const value in filters) {
                if (filters[value] !== "") {
                  valuesCompleted.push(value);
                }
            }
        
            let query = "";
            valuesCompleted.forEach((value, index) => {
                query += `${value}=${filters[value]}`;
                if (index !== valuesCompleted.length - 1) query += `&`;
            });
        
            setParams(query)
        }
    };

    // Limpiar filtros
    const clearSearch = () => {
        setParams("")
        setInputValues(filterValues)
    };

    return {
        inputValues,
        params,
        handleChange,
        handleSearch,
        clearSearch,
        handleChangeExternal
    }
}
