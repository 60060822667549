import styled, { css } from 'styled-components';
import { breakpoints, leerColor, colores } from '../../../constants';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;

    a {
      text-decoration: none;
    }
    
  hr {
    height: 1px;
    width: 100%;
    opacity: 0.2;
    //margin: 20px 0 20px 0;
  }

  p {
    margin: 0 0 0px 0;
  }

  h3 {
    margin-top: 0;
    color: ${leerColor(colores.celeste)}
  }

  h4 {
    margin: 10px 0;
  }

  .container-botones {
    display: flex;
    gap: 10px;
  }

  .premio-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    gap: 10px;
    box-sizing: border-box;
    padding: 10px 20px;

    span {
        font-size: 14px;
        color: ${leerColor(colores.grisEstados)};
    }

    h2 {
        margin: 0;
        font-weight: 500;
        font-size: 24px;
    }

    .link {
        color: ${leerColor(colores.grisEstados)};
        display: flex;
        align-items: center;
        gap: 6px;
        transition: 0.2s;
        cursor: pointer;
    }

    .link:hover {
        color: ${leerColor(colores.azulRebranding)};
    }
  }

  .total {
    border: 1px solid ${leerColor(colores.negro)};
    color: ${leerColor(colores.blanco)};
    background-color: ${leerColor(colores.negro)};

    span {
      color: ${leerColor(colores.blanco)};

    }
  }

  .data-container {
    padding: 20px 0;
    //border: 1px solid #eaeaea;
    border-radius: 6px;
    box-sizing: border-box;
  }

  /*
  .data-icon {
    font-size: 24px;
  }
  */

  .grupo-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #eaeaea;

    .integrantes-container {
      display: flex;
      gap: 10px;
      border-top: 1px solid #eaeaea;
      padding: 20px;
    }

    h3 {
      font-size: 20px;
      color: ${leerColor(colores.negro)};
      padding: 20px;
      margin: 0;
    }

    span {
      font-size: 14px;
      color: ${leerColor(colores.grisEstados)}
    }
  }

  /*
  .icon {
    font-size: 36px;
    color: ${leerColor(colores.azulRebranding)};
  }
  */

  .descripcion {
    font-size: 14px;
    color: ${leerColor(colores.grisEstados)}
  }

  .agregar-vendedor-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px dashed ${leerColor(colores.azulRebranding)};
    box-sizing: border-box;
    padding: 40px;
    color: ${leerColor(colores.grisEstados)};
    cursor: pointer;
    transition: 0.2;
    height: 175.5px;

    .icon {
      font-size: 32px;
      color: ${leerColor(colores.azulRebranding)};
    }

    h2 {
      color: ${leerColor(colores.azulRebranding)};
      margin: 0;
    }

    .vendedor-nombre {
      color: ${leerColor(colores.negro)};
    }

    :hover {
        opacity: 0.7;
    }
  }

  .vendedor-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    padding: 40px;
    gap: 10px;
    color: ${leerColor(colores.grisEstados)};
    cursor: pointer;
    transition: 0.2;

    h2 {
      color: ${leerColor(colores.azulRebranding)};
      margin: 0;
    }

    .vendedor-nombre {
      color: ${leerColor(colores.negro)};
    }

    :hover {
        opacity: 0.7;
    }
  }

  .no-seleccionado {
    opacity: 0.5;
    filter: grayscale();
  }

  .seleccionado {
    color: ${leerColor(colores.azulRebranding)};
    border: 1px solid ${leerColor(colores.azulRebranding)};
    font-weight: 500;
  }
`

export const ContainerInfo = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-top: 60px;
  //border-top: 1px solid #eaeaea;
  padding: 120px 0;

  p {
    margin: 0;
  }

  strong {
    color: ${leerColor(colores.azul)}
  }

  .vendedor-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    padding: 40px;
    gap: 10px;
    color: ${leerColor(colores.grisEstados)};
    cursor: pointer;
    transition: 0.2;

    :hover {
        opacity: 0.7;
    }
  }

  .seleccionado {
    color: ${leerColor(colores.azulRebranding)};
    border: 1px solid ${leerColor(colores.azulRebranding)};
    font-weight: 500;
  }
`

export const Icon = styled.span`
    //font-size: 60px;
    display: flex;
    color: ${leerColor(colores.celeste)}
`