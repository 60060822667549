import React from "react";
import Subtitle from "../../../Subtitle";
import { FiEdit, FiFile } from "react-icons/fi";
import { Col, Grid } from "../../../Grid";
import dayjs from "dayjs";
import ErrorInput from "../../../ErrorInput";
import NewInputSelect from "../../../NewInputSelect";
import NewInputTextarea from "../../../NewInputTextarea";
import NewInputBasic from "../../../NewInputBasic";

const SiniestroInformacionTab = ({ form, data }) => {
  const { inputValues, handleChange, formErrors } = form  
  const { siniestro, nombreProducto, estados } = data
  
  return (
    <>
      {siniestro.tipo_carga_siniestro === 2 ? (
        <>
          <Subtitle
            icon={<FiFile />}
            title={"Denuncia del asegurado"}
            description={
              "Información detallada por el asegurado desde la landing"
            }
            disableDivider={true}
          />
          <Grid colGap={21} rowGap={21} narrow={true}>
            <Col desktop={3}>
              <div className={`premio-container`}>
                <span>Email</span>
                <h2>{siniestro.email_asegurado_siniestro}</h2>
              </div>
            </Col>
            <Col desktop={3}>
              <div className={`premio-container`}>
                <span>Teléfono</span>
                <h2>
                  {siniestro.codigo_area_asegurado_siniestro}
                  {siniestro.telefono_asegurado_siniestro}
                </h2>
              </div>
            </Col>
            <Col desktop={3}>
              <div className={`premio-container`}>
                <span>Fecha del siniestro</span>
                <h2>{dayjs(siniestro.fecha).format("DD-MM-YYYY")}</h2>
              </div>
            </Col>
            <Col desktop={3}>
              <div className={`premio-container`}>
                <span>Hora del siniestro</span>
                <h2>{siniestro.hora_siniestro}</h2>
              </div>
            </Col>
            <Col desktop={3}>
              <div className={`premio-container`}>
                <span>Provincia</span>
                <h2>{siniestro.provincia_siniestro}</h2>
              </div>
            </Col>
            <Col desktop={3}>
              <div className={`premio-container`}>
                <span>Localidad</span>
                <h2>{siniestro.localidad_siniestro}</h2>
              </div>
            </Col>
            <Col desktop={3}>
              <div className={`premio-container`}>
                <span>Calle</span>
                <h2>{siniestro.calle_siniestro}</h2>
              </div>
            </Col>
            <Col desktop={3}>
              <div className={`premio-container`}>
                <span>Altura</span>
                <h2>{siniestro.altura_siniestro}</h2>
              </div>
            </Col>
            <Col desktop={12}>
              <div className={`premio-container`}>
                <span>Reseña</span>
                <h2>{siniestro.descripcion_asegurado_siniestro}</h2>
              </div>
            </Col>
          </Grid>
          <hr />
        </>
      ) : null}
      <Subtitle
        icon={<FiFile />}
        title={"Resumen"}
        description={"Información relevante al siniestro denunciado"}
        disableDivider={true}
      />
      <Grid colGap={21} rowGap={21} narrow={true}>
        <Col desktop={3}>
          <div className={`premio-container`}>
            <span>Asegurado</span>
            <h2>{siniestro.asegurado}</h2>
          </div>
        </Col>
        <Col desktop={3}>
          <div className={`premio-container`}>
            <span>Compañia</span>
            <h2>{siniestro.compania}</h2>
          </div>
        </Col>
        <Col desktop={3}>
          <div className={`premio-container`}>
            <span>DNI</span>
            <h2>{siniestro.dni_siniestro}</h2>
          </div>
        </Col>
        <Col desktop={3}>
          <div className={`premio-container`}>
            <span>Fecha del Siniestro</span>
            <h2>{dayjs(siniestro.fecha).format("DD/MM/YYYY")}</h2>
          </div>
        </Col>
        <Col desktop={3}>
          <div className={`premio-container`}>
            <span>Póliza</span>
            <h2>{siniestro.poliza}</h2>
          </div>
        </Col>
        <Col desktop={3}>
          <div className={`premio-container`}>
            <span>Ramo</span>
            <h2>{siniestro.ramo}</h2>
          </div>
        </Col>
        <Col desktop={3}>
          <div className={`premio-container`}>
            <span>Tipo</span>
            <h2>{siniestro.tipo_siniestro}</h2>
          </div>
        </Col>
        {nombreProducto ? (
          <Col desktop={3}>
            <div className={`premio-container`}>
              <span>Producto</span>
              <h2>{nombreProducto}</h2>
            </div>
          </Col>
        ) : null}
        {siniestro.informe_siniestro ? (
          <Col desktop={12}>
            <div className={`premio-container`}>
              <span>Informe</span>
              {siniestro.informe_siniestro.split("\n").map((item) => {
                return <h2>{item}</h2>;
              })}
            </div>
          </Col>
        ) : null}
      </Grid>
      <hr />
      <Subtitle
        icon={<FiEdit />}
        title={"Información modificable"}
        description={"Información relevante al siniestro denunciado"}
        disableDivider={true}
      />
      <Grid colGap={21} rowGap={21} narrow={true}>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"mail_siniestro"}
            labelName={"Mail"}
            placeholder={"Mail"}
            onChange={handleChange}
            value={inputValues.mail_siniestro}
          />
          {formErrors.mail_siniestro ? (
            <ErrorInput>{formErrors.mail_siniestro}</ErrorInput>
          ) : null}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"telefono_siniestro"}
            labelName={"Teléfono"}
            placeholder={"Teléfono"}
            onChange={handleChange}
            value={inputValues.telefono_siniestro}
          />
          {formErrors.telefono_siniestro ? (
            <ErrorInput>{formErrors.telefono_siniestro}</ErrorInput>
          ) : null}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"num_siniestro"}
            labelName={"Número Siniestro"}
            placeholder={"Número Siniestro"}
            onChange={handleChange}
            value={inputValues.num_siniestro}
          />
          {formErrors.num_siniestro ? (
            <ErrorInput>{formErrors.num_siniestro}</ErrorInput>
          ) : null}
        </Col>
        <Col desktop={3}>
          <NewInputSelect
            name={"estado"}
            labelName={"Estado"}
            options={estados}
            onChange={handleChange}
            value={inputValues.estado}
          />
          {formErrors.estado && (
            <span className="error">{formErrors.estado}</span>
          )}
        </Col>
        {inputValues.estado === "Cerrado positivo - Conforme" ? (
          <>
            <Col desktop={3}>
              <NewInputBasic
                type={"number"}
                name={"suma_pagada_siniestro"}
                labelName={"Suma Pagada"}
                placeholder={"250000"}
                onChange={handleChange}
                value={inputValues.suma_pagada_siniestro}
              />
              {formErrors.suma_pagada_siniestro && (
                <span className="error">
                  {formErrors.suma_pagada_siniestro}
                </span>
              )}
            </Col>
            <Col desktop={3}>
              <NewInputBasic
                type={"number"}
                name={"gastos_liquidacion_siniestro"}
                labelName={"Gastos Liquidación"}
                placeholder={"250000"}
                onChange={handleChange}
                value={inputValues.gastos_liquidacion_siniestro}
              />
              {formErrors.gastos_liquidacion_siniestro && (
                <span className="error">
                  {formErrors.gastos_liquidacion_siniestro}
                </span>
              )}
            </Col>
          </>
        ) : null}
        <Col desktop={3}>
          <NewInputBasic
            type={"number"}
            name={"suma_asegurada_siniestro"}
            labelName={"Suma Asegurada"}
            placeholder={"250000"}
            onChange={handleChange}
            value={inputValues.suma_asegurada_siniestro}
          />
          {formErrors.suma_asegurada_siniestro && (
            <span className="error">{formErrors.suma_asegurada_siniestro}</span>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"number"}
            name={"ajuste"}
            labelName={"Ajuste"}
            placeholder={"250000"}
            onChange={handleChange}
            value={inputValues.ajuste}
          />
          {formErrors.ajuste && (
            <span className="error">{formErrors.ajuste}</span>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"number"}
            name={"franquicia"}
            labelName={"Franquicia"}
            placeholder={"250000"}
            onChange={handleChange}
            value={inputValues.franquicia}
          />
          {formErrors.franquicia && (
            <span className="error">{formErrors.franquicia}</span>
          )}
        </Col>
        <Col desktop={12}>
          <NewInputTextarea
            type={"text"}
            name={"informe_siniestro"}
            labelName={"Informe"}
            placeholder={"Informe"}
            maxLength={"1000"}
            height={"100px"}
            onChange={handleChange}
            value={inputValues.informe_siniestro}
          />
          {formErrors.informe_siniestro && (
            <span className="error">{formErrors.informe_siniestro}</span>
          )}
        </Col>
      </Grid>
    </>
  );
};

export default SiniestroInformacionTab;
