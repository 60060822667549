import React from "react";
import Subtitle from "../../Subtitle";
import { FiFile } from "react-icons/fi";
import { Col, Grid } from "../../Grid";
import NewInputBasic from "../../NewInputBasic";
import ErrorInput from "../../ErrorInput";
import NewInputCalendar from "../../NewInputCalendar";
import NewInputSelect from "../../NewInputSelect";
import InputFile from "../../InputFile";

const CrearSiniestroForm = ({ form, data }) => {
    
  const { inputValues, handleChange, handleChangeExternal, formErrors } = form  
  const { productos, tipos } = data

  return (
    <>
      <Subtitle
        icon={<FiFile />}
        title={"Sobre el siniestro"}
        description={"Información relevante al siniestro denunciado"}
        disableDivider={true}
      />
      <Grid colGap={21} rowGap={21} narrow={true}>
        <Col desktop={3}>
          <NewInputBasic
            type={"number"}
            name={"dni"}
            labelName={"DNI"}
            placeholder={"40955581"}
            onChange={handleChange}
            value={inputValues.dni}
          />
          {formErrors.dni && (
            <ErrorInput className="error">{formErrors.dni}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"number"}
            name={"poliza"}
            labelName={"Póliza"}
            placeholder={"900034"}
            onChange={handleChange}
            value={inputValues.poliza}
          />
          {formErrors.poliza && (
            <ErrorInput className="error">{formErrors.poliza}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputCalendar
            type={"date"}
            name={"fecha_siniestro"}
            labelName={"Fecha Siniestro"}
            onChange={handleChange}
            readOnly={false}
            value={inputValues.fecha_siniestro}
          />
          {formErrors.fecha_siniestro && (
            <ErrorInput className="error">
              {formErrors.fecha_siniestro}
            </ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputSelect
            name={"producto"}
            labelName={"Producto"}
            options={productos}
            placeholder={"Producto"}
            onChange={handleChange}
            value={inputValues.producto}
          />
          {formErrors.producto && (
            <ErrorInput className="error">{formErrors.producto}</ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputSelect
            name={"tipo_siniestro"}
            labelName={"Tipo del Siniestro"}
            options={tipos}
            placeholder={"Tipo del Siniestro"}
            onChange={handleChange}
            value={inputValues.tipo_siniestro}
          />
          {formErrors.tipo_siniestro && (
            <ErrorInput className="error">
              {formErrors.tipo_siniestro}
            </ErrorInput>
          )}
        </Col>
        <Col desktop={3}>
          <NewInputBasic
            type={"text"}
            name={"num_siniestro"}
            labelName={"Número Siniestro"}
            placeholder={"Número Siniestro"}
            onChange={handleChange}
            value={inputValues.num_siniestro}
          />
          {formErrors.num_siniestro && (
            <ErrorInput className="error">
              {formErrors.num_siniestro}
            </ErrorInput>
          )}
        </Col>
        <Col desktop={6}>
          <NewInputBasic
            type={"text"}
            name={"informe"}
            labelName={"Informe"}
            placeholder={"Informe"}
            onChange={handleChange}
            value={inputValues.informe}
          />
          {formErrors.informe && (
            <ErrorInput className="error">{formErrors.informe}</ErrorInput>
          )}
        </Col>
      </Grid>
      <hr />
      <Subtitle
        icon={<FiFile />}
        title={"Documentación"}
        description={"Adjuntá todos los archivos relacionados al siniestro"}
        disableDivider={true}
      />
      <Grid colGap={21} rowGap={21} narrow={true}>
        <Col desktop={12}>
          <InputFile
            type={"file"}
            name={"adjuntos"}
            text={"Adjuntar documentación del siniestro"}
            labelName={""}
            required={true}
            multiple={true}
            onChange={handleChangeExternal}
            files={inputValues.adjuntos}
            filesGrid={true}
          />
        </Col>
      </Grid>
    </>
  );
};

export default CrearSiniestroForm;
