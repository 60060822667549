import React from "react";
import { Container } from "./styled";
import { FiGlobe } from "react-icons/fi";
import { LOGOS_COMPANIAS } from "../../constants/logos-companias";
import Estado from "../Estado";

const HeaderTitleSection = ({ compania, vendedor, estado }) => {
  const logo = LOGOS_COMPANIAS.find(
    (item) => item.nombre.toLowerCase() === compania?.toLowerCase()
  )?.url;
  return (
    <Container>
      <div className="header-section">
        <img src={logo} className="logo-compania" alt="logo de la compañia" />
        {/*
        <span className="header-info">
            Estado actual: <Estado estado={estado} />
        </span>
        */}
        {vendedor === 192 ?
        <span className="header-info autogestion">
          <span>
            <FiGlobe />
          </span>{" "}
          Autogestión
        </span>
        : null}
      </div>
    </Container>
  );
};

export default HeaderTitleSection;
