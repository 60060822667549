import { useEffect, useState, useRef } from "react";
import axiosRequest from "../utils/axiosConfig";
import usePagination from "./usePagination";
import { useLoading } from "./useLoading";
import { getAllUsuarios, getAllRoles, crearNuevoUsuario } from "../services/Usuarios";
// Custom Hooks
import { useForm } from "../hooks/useForm";
import { datosUsuariosReglas } from "../utils/formRules";
import { usuarioForm } from "../utils/formInitialValues";
import { datosGenerarClaveReglas } from "../utils/formRules";
import { generarClaveForm } from "../utils/formInitialValues";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const useUsuarios = (filterParams, orderParams, handleModal, modalExito, nuevaClaveModal) => {

  // Estados
  const history = useHistory(); // Hook de router
  const [editMode, setEditMode] = useState(false)
  const [usuarios, setUsuarios] = useState([])
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(null)
  const [roles, setRoles] = useState([])
  const [usuariosLength, setUsuariosLength] = useState(null)
  const [success, setSuccess] = useState(false)
  const estados = [
    {
        'label': 'Activo',
        'value': 1,
        'key':  1
    },
    {
        'label': 'Inactivo',
        'value': 0,
        'key':  0
    },
  ]

  // Custom Hooks
  const { inputValues, handleChange, handleChangeCheckbox, formErrors, handleValidation, resetForm, resetFormErrors } = useForm(handleSubmit, datosUsuariosReglas, usuarioForm, editMode)
  const formClave = useForm(submitClave, datosGenerarClaveReglas, generarClaveForm)
    // Paginación
    const pagination = usePagination(usuariosLength)

    // Filter ref
    const filtrosRef = useRef(null)

    const { loading, setLoading, loadingText, changeLoadingText } = useLoading(true)
    const loadingModalUsuario = useLoading()

    const refreshUsuarios = async () => {
      setLoading(true)
      setUsuarios([])
      let paginacion = filtrosRef.current !== filterParams ? 1 : pagination.currentPage
      if(filtrosRef.current !== filterParams){
        filtrosRef.current = filterParams
        pagination.selectPage(1)
      }
      try {
          const usuarios = await getAllUsuarios(filterParams, orderParams, paginacion)
          const usuariosLength = await getAllUsuarios(filterParams, orderParams, null)
          setUsuarios(usuarios)
          setUsuariosLength(usuariosLength.length)
          setLoading(false)
      } catch (error) {
          setLoading(false)
          console.log(error)
      }
    }

    useEffect(() => {
      refreshUsuarios()
    }, [filterParams, orderParams, pagination.currentPage])

    const getInfoUsuario = async (id, completeForm) => {
      try {
          changeLoadingText("Cargando información de usuario")
          loadingModalUsuario.setLoading(true)
          const send = await axiosRequest.get(`/operadores/${id}`);
          console.log(send.data)
          const siniestrosInfo = await axiosRequest.get(`/siniestros_vendedores/${send.data.email_operador}`)
          console.log(siniestrosInfo)
          let fechaFormated = send.data.nacimiento_operador.substr(0,send.data.nacimiento_operador.indexOf('T'))
          if(siniestrosInfo.data){
              const { total, parcial } = siniestrosInfo.data             
              completeForm({...send.data, total: total, parcial: parcial, siniestros_habilitado_usuario: true, nacimiento_operador:fechaFormated, clave_usuario:''})
          } else {
              completeForm({...send.data, total: false, parcial: false, siniestros_habilitado_usuario: false, nacimiento_operador:fechaFormated, clave_usuario:''})
          }
          loadingModalUsuario.setLoading(false)
      } catch (error) {
          console.log(error)
          loadingModalUsuario.setLoading(false)
      }
    }

    const editarUsuario = (id) => {
      getInfoUsuario(id)
      setEditMode(true)
      handleModal()
    }

    const abrirModalClave = (usuario) => {
      formClave.resetForm({})
      formClave.resetFormErrors()
      nuevaClaveModal.handleModal()
      setUsuarioSeleccionado(usuario)
    }

    const abrirModalCrearUsuario = () => {
      setEditMode(false)
      resetForm({})
      resetFormErrors()
      handleModal()
    }

    const enviarCambiosUsuario = async (values)=>{

          try {
              const send = await axiosRequest.put(`/operadores/${values.id_operador}`, values)
              if(send.data){
                  return true
              }  
          } catch (error) {
            console.log(error)
              //setError({msg:'Error al obtener info del operador', type:'main'})
          }
      
  }

  const actualizarUsuario = async (values) => { 
    // cambia los datos del usuario
        try {
            const update = await axiosRequest.put(`/usuarios/${values.id_usuario}`, values);
            if(values.id_rol === 10 || values.id_rol === 18) await axiosRequest.post(`/siniestros_vendedores`, values);
            if(update.status === 200){
                return true
            }
        } catch (error) {
            console.log(error)
            console.log(error.data)
            console.log(error.response)
            //setError({msg:'Error al actualizar los datos del usuario', type:'main'})
        }
}

    const editarUsuarioData = async (values) => {
      setLoading(true)
      const cambiosUser = await enviarCambiosUsuario(values)
      const actualizarUser = await actualizarUsuario(values)
      if(cambiosUser && actualizarUser){
          //history.push("/Usuarios")
          setLoading(false)
          setSuccess(true)
      }
  }

  const generarNuevaClave = async (id_usuario, values, handleModal) => {
    const datosParaActualizar = {id_usuario: id_usuario, clave_usuario: values.clave_usuario}
        try {
            const cambiarClave = await axiosRequest.put(`/usuarios/password/${id_usuario}`, datosParaActualizar);
            if(cambiarClave.status === 200){
              handleModal()
              /*
              nuevaClaveModal.handleModal()
              modalExito.setIsModalOpen(true);
              modalExito.handleModalMessage("¡Contraseña actualizada!")
              setTimeout(() => {
                modalExito.setIsModalOpen(false);
              }, 1200);
              */
          }  
        } catch (error) {
            console.log(error)
        }
  }
  

  async function submitClave() {
    generarNuevaClave()
  }

  async function getUsuarios(){
    try {
        const usuarios = await getAllUsuarios(null, null, 1)
        const usuariosLength = await getAllUsuarios(null, null, null)
        console.log(usuarios)
        setUsuarios(usuarios)
        setUsuariosLength(usuariosLength.length)
        setLoading(false)
    } catch (error) {
        setLoading(false)
        console.log(error)
    }
  }

  async function getRoles(){
    try {
        const roles = await getAllRoles()
        console.log(roles)
        setRoles(roles)
    } catch (error) {
        console.log(error)
    }
}

  useEffect(() => {
    getUsuarios()
    getRoles()
  }, [])

  const crearUsuario = async (values) => {
    try {
      console.log(values)
      const usuario = await crearNuevoUsuario(values)
      if(usuario.data){
          history.push("/Usuarios")

          /*
          refreshUsuarios();
          handleModal()
          modalExito.setIsModalOpen(true);
          modalExito.handleModalMessage("¡Usuario creado!")
          setTimeout(() => {
            modalExito.setIsModalOpen(false);
          }, 1200);
          */
      }  
    } catch (error) {
        console.log(error)
    }
  }

  async function handleSubmit(values) {
    if(editMode) {
      editarUsuarioData()
    } else {
      crearUsuario(values)
    }
  }

  return {  
    data: {
        usuarios: usuarios, 
        roles: roles,
        estados: estados,
    },
    loading,
    success,
    pagination,
    refreshUsuarios,
    usuarios: {
      crearUsuario: crearUsuario,
      editarUsuario: editarUsuario,
      loading: loadingModalUsuario.loading,
      loadingText: loadingText,
      editMode: editMode,
      abrirModalCrearUsuario: abrirModalCrearUsuario, 
      generarNuevaClave: generarNuevaClave,
      abrirModalClave: abrirModalClave, 
      usuarioSeleccionado: usuarioSeleccionado,
      getInfoUsuario: getInfoUsuario,
      editarUsuarioData: editarUsuarioData
    },
    form: {
      inputValues: inputValues,
      handleChange: handleChange,
      handleChangeCheckbox: handleChangeCheckbox,
      formErrors: formErrors,
      handleValidation: handleValidation
    },
    formClave: {
      inputValues: formClave.inputValues,
      handleChange: formClave.handleChange,
      formErrors: formClave.formErrors,
      handleValidation: formClave.handleValidation
    },

  };
}